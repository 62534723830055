import todoAppImage from './images/for-task-app.png';
import landingPageImage from './images/hof-img.png';
import artistportfolio from './images/cover-presentation.png';
import genproje from './images/2-cover.png';
import personalportfolio from './images/portdolio-img.png';
import weatherimage from './images/cloud.png';
import invoices from './images/invoice.png';
import layout from './images/layout.png';
import loginimage from './images/login-imag.png';
import bnbmobile  from './images/bnb-mobile-img.png';
import bnbdesktop from './images/bnb-desktop-img.png';
import taskmodal from './images/task-img.png';
import hofindalp from './images/Cover.png';
import hofindalp1 from './images/Personas.png';
import hofindalp2 from './images/desktophof.png';
import hofindalp3 from './images/mobilehof.png';
import hofgif1 from './images/hofgif1.gif';
import hofgif2 from './images/hofgif2.gif';
import gen1 from './images/13.png';
import gen2 from './images/14.png';
import gen3 from './images/15.png'; 
import gen4 from './images/16.png'; 
import gen5 from './images/17.png'; 
import gen6 from './images/14.gif'; 
import vi1 from './images/18.png';
import vi2 from './images/19.png';
import vi3 from './images/20.png';
import vi4 from './images/21.png';
import vi5 from './images/22.png';
import vi6 from './images/23.gif';
import vi7 from './images/24.png';
import vi8 from './images/25.png';
import vi9 from './images/for-music.png';
import vi10 from './images/vi10.png';
import vi11 from './images/vi11.png';

// Import other images as needed...
const projects = [
  { 
    id: 1, 
    title: 'React Landing Page', 
    category: ['React', 'UX/UI','Front-End'],  
    imagePath: vi9, 
    imageLink: 'https://example.com/hofinda',
    description: 'This React landing page template for a music studio features smooth scrolling, email integration,'+
     ' and a responsive design to showcase services and team.',
    projectUrl: 'https://example.com/hofinda',
    codeUrl: 'https://www.ariane-dev.music-studio.adwstudium.com/',
    buttonTextLive: 'See the Project',
    buttonTextCode: 'See Live Page',
    modalTitle: 'Description',
    modalDescription: 'This React-based landing page template for a music studio is fully responsive and dynamic. Key features include:'+
     ' Smooth Scrolling: Provides a seamless navigation experience.\n\n'+
     'Email Integration: Uses emailjs-com for direct contact without a backend.\n\n'+
     'Modern Design: Clean, user-friendly layout showcasing studio services and team.\n\n'+
     'Responsive Layout: Works perfectly on all device sizes.',
    modalSkills: 'Frontend, UX/UI ',
    modalSoftware: 'React', 
    modalImages: [ vi10, vi11], 
    modalImagePath:vi9,
    modalLink: 'https://github.com/Arireal/https---github.com-Arireal-music-studio-landing-page',
    modalButtonText: 'See the code' // or 'See the website'
  }, 
  { 
    id: 1, 
    title: 'React Authentication Page with JWT', 
    category: ['React', 'Node','PostgreSQL','Full-Stack'],  
    imagePath: loginimage, 
    imageLink: 'https://example.com/hofinda',
    description: 'A full-stack system with a Figma-designed login and dashboard, featuring a Node.js backend with JWT for authentication,'+
     ' and a React frontend for user management and protected routes.',
    projectUrl: 'https://example.com/hofinda',
    codeUrl: 'https://www.ariane-dev.airbnb.adwstudium.com/',
    buttonTextLive: 'See the Project',
    buttonTextCode: 'See Live Page',
    modalTitle: 'Description',
    modalDescription: 'A full-stack system with a Figma-designed login and dashboard, featuring a Node.js backend with JWT for authentication,'+
     ' and a React frontend for user management and protected routes.',
    modalSkills: 'Frontend, Backend and UX/UI ',
    modalSoftware: 'PostgreSQL, Full-Stack, Node, Figma, React', 
    modalImages: [ bnbmobile, bnbdesktop], 
    modalImagePath:loginimage,
    modalLink: 'https://github.com/Arireal/airbnb-auth-app',
    modalButtonText: 'See the code' // or 'See the website'
  },
  { 
    id: 4, 
    title: 'React Portfolio Website', 
    category: ['React', 'UX/UI','Frontend', 'Gsap','Git',], 
    imagePath: personalportfolio, 
    description: 'A portfolio I created in React, featuring GSAP animation effects, intended to showcase my selected Full-Stack projects.',
    projectUrl: 'https://example.com/hofinda',
    codeUrl: 'https://ariane-dev.adwstudium.com/',
    buttonTextLive: 'See the Project',
    buttonTextCode: 'See Live Website',
    modalTitle: 'Description',
    modalDescription: 'A portfolio I created using React, featuring GSAP animation effects, intended to showcase my selected Full-Stack projects.'+
    ' The code was purely client-side and relied on React for UI components and state management, JavaScript for logic, and GSAP for animations.',
    modalSkills: 'Frontend, UX/UI',
    modalSoftware: 'Git Version Control, React, Javascript, Css, Gsap', 
    modalImagePath:personalportfolio,
    modalLink: 'https://github.com/Arireal/https---github.com-Arireal-my-portfolio',
    modalButtonText: 'See the Code' // or 'See the website'
  },
    { 
      id: 2, 
      title: 'Tasks App', 
      category: ['Python', 'Streamlit', 'Backend', 'GUI'], 
      imagePath: todoAppImage,
      imageLink: 'https://example.com/hofinda', 
      description: 'This is a Python-based Task application that helps manage daily tasks efficiently. ' +
      'Developed with Python, using Git for version control, and a GUI library for a clean, intuitive interface. ',
      projectUrl: 'https://example.com/hofinda',
      codeUrl: 'https://github.com/Arireal/my-todo-web-app',
      buttonTextLive: 'See the Project',
      buttonTextCode: 'See the Code',
      modalTitle: 'Description',
      modalDescription: 'This task web app is crafted to streamline task management, helping users enhance their productivity by efficiently creating and completing tasks.'+ 
      ' Developed using Python, the project highlights my skills in software development and version control.\n\n'+ 
      ' The use of Git ensures robust version tracking and collaboration capabilities, demonstrating my proficiency in managing codebases and working in team environments.'+
      ' The user interface is crafted using a GUI library, providing a clean and intuitive experience for users.',
      modalSkills: 'Python, Git Version Control, Team Environments ',
      modalSoftware: 'Pycharm, GUI, and Streamlit library', 
      modalImages: [taskmodal],
      modalImagePath:todoAppImage,
      modalLink: 'https://github.com/Arireal/my-todo-web-app',
      modalButtonText: 'See the code' // or 'See the website'
    },
    { 
        id: 3, 
        title: 'Book Landing Page', 
        category: ['UX/UI', 'Figma', 'Frontend', 'Full-Stack'], 
        imagePath: landingPageImage, 
        description: 'The goal of this landing page was to promote the book, with a strong emphasis on mobile optimization, as most of the audience accesses it via mobile devices.',
        projectUrl: 'https://example.com/hofinda',
        codeUrl: 'https://www.hofindatheresumptionofties.aogaroi.com/',
        buttonTextLive: 'See the Project',
        buttonTextCode: 'See Live Website',
        modalTitle: 'Description',
        modalDescription: 'The goal of this landing page was to promote the book, with a strong emphasis on mobile optimization, as most of the audience accesses it via mobile devices.',
        modalSkills: 'Frontend Developer, Backend Developer, Web Design (UX/UI) Visual Identity',
        modalSoftware: 'HTML5, CSS, JavaScript libraries, flex grids, and SEO settings, PHP', 
        modalImages: [hofindalp, hofindalp1, hofgif1, hofindalp2, hofindalp3, hofgif2],
        modalImagePath:landingPageImage,
        modalLink: 'https://www.hofindatheresumptionofties.aogaroi.com/',
        modalButtonText: 'See the Website' // or 'See the website'
      },
      { 
        id: 5, 
        title: 'PDF Templates RESTful API', 
        category: ['Python', 'Backend', 'Git'], 
        imagePath: layout , 
        description: 'A Python script that generates multi-page PDF templates based on predefined guidelines,'+ 
        ' utilizing libraries such as FPDF for precise and customizable document creation.',
        projectUrl: 'https://example.com/hofinda',
        codeUrl: 'https://github.com/Arireal/pdf-converter',
        buttonTextLive: 'See the Project',
        buttonTextCode: 'See the Code',
        modalTitle: 'Description',
        modalDescription: 'This PDF template generator is a Python script designed to create multi-page PDF documents following specific predefined guidelines.'+
        ' By leveraging the FPDF library, the script ensures precise and customizable template creation, making it ideal for a variety of documentation needs.\n\n'+
        ' Utilizing Git for version control, the project showcases my skills in managing and tracking code changes, promoting effective collaboration and robust'+ 
        ' development practices. The ability of the scritp to generate consistent and high-quality PDF templates demonstrates my proficiency in Python programming'+ 
        ' and my attention to detail in adhering to design specifications.',
        modalSkills: 'Backend, API Development',
        modalSoftware: 'Git Version Control, Python development, Version control with Git, PDF creation with FPDF.', 
        modalImagePath: layout,
        modalLink: 'https://github.com/Arireal/pdf-converter',
        modalButtonText: 'See the Code' // or 'See the website'
      },
      { 
        id: 6, 
        title: 'PDF Invoices RESTful API', 
        category: ['Python', 'Backend', 'Git'], 
        imagePath: invoices , 
        description: 'A Python-based PDF invoice generator that reads invoice records from Excel'+ 
        ' files and automatically creates PDF invoices using Pandas and FPDF libraries.',
        projectUrl: 'https://example.com/hofinda',
        codeUrl: 'https://github.com/Arireal/pdf-invoices',
        buttonTextLive: 'See the Project',
        buttonTextCode: 'See the Code',
        modalTitle: 'Description',
        modalDescription: 'This PDF invoice generator is a Python script designed to streamline the'+ 
        ' invoicing process by reading invoice records from Excel files and automatically generating PDF invoices.'+ 
        ' The project leverages Pandas for efficient data manipulation and FPDF for creating professional-quality PDF documents.\n\n'+
        ' The use of Git for version control ensures robust version tracking and collaboration capabilities, highlighting my proficiency'+ 
        ' in managing codebases and working in team environments. This tool demonstrates my ability to integrate and utilize powerful'+ 
        ' libraries to automate and enhance business processes.',
        modalSkills: 'Backend, API Development, Data manipulation.',
        modalSoftware: 'Git Version Control, Python development, Data manipulation with Pandas, and PDF generation with FPDF.', 
        modalImagePath: invoices,
        modalLink: 'https://github.com/Arireal/pdf-invoices',
        modalButtonText: 'See the Code' // or 'See the website'
      },
      { 
        id: 7, 
        title: 'Weather RESTful API', 
        category: ['Python', 'Backend', 'Git', 'Data Analysis'], 
        imagePath: weatherimage , 
        description: 'A dynamic weather forecast API developed in Python using Git for version control,'+ 
        ' and leveraging Plotly and Streamlit libraries for data visualization and user interface.'+  
        ' This project provides accurate and visually appealing weather data.',
        projectUrl: 'https://example.com/hofinda',
        codeUrl: 'https://github.com/Arireal/Weather_API_Dashboard',
        buttonTextLive: 'See the Project',
        buttonTextCode: 'See the Code',
        modalTitle: 'Description',
        modalDescription: 'This weather forecast API is designed to provide accurate and visually engaging weather data,'+ 
        ' showcasing my expertise in Python development, version control, and data visualization.'+ 
        ' The project is managed using Git, ensuring robust version control and collaborative potential, which underscores my proficiency in managing'+ 
        ' codebases and working in team environments.\n\n'+
        ' The API leverages Plotly for creating interactive and high-quality visualizations, while Streamlit'+ 
        ' is used to develop an intuitive and user-friendly interface. These choices highlight my ability to'+ 
        ' integrate and utilize powerful libraries to enhance the functionality and visual appeal of applications.',
        modalSkills: 'Backend, API Development, Data manipulation.',
        modalSoftware: 'Streamlit, Plotly, Python, Git Version Control', 
        modalImagePath: weatherimage,
        modalLink: 'https://github.com/Arireal/Weather_API_Dashboard',
        modalButtonText: 'See the Code' // or 'See the website'
      },
      { 
        id: 8, 
        title: 'PHP Debug', 
        category: ['UX/UI', 'Wordpress', 'Full-Stack', 'PHP',], 
        imagePath: genproje , 
        description: 'A debugging task I completed on a WordPress online college website to ensure it worked properly.'+
        ' I also made some UX improvements while adhering to the existing visual identity.',
        projectUrl: 'https://example.com/hofinda',
        codeUrl: 'https://portalgenotec.com.br/',
        buttonTextLive: 'See the Project',
        buttonTextCode: 'See Live Website',
        modalTitle: 'Description',
        modalDescription: 'A debugging task I completed on a WordPress online college website to ensure it worked properly.'+
        ' I also made some UX improvements while adhering to the existing visual identity.',
        modalSkills: 'Frontend, UX/UI, Back-End',
        modalSoftware: 'PHP, Wordpress',
        modalImages: [gen1, gen2, gen3, gen4, gen5, gen6],
        modalImagePath:genproje ,
        modalLink: 'https://portalgenotec.com.br/',
        modalButtonText: 'See the Website' // or 'See the website'
      },
      { 
        id: 9, 
        title: 'Grid Portfolio Web App', 
        category: ['UX/UI', 'Full-Stack','PHP','HTML'], 
        imagePath: artistportfolio , 
        description: 'Developed a responsive grip portfolio website for a visual artist using HTML, CSS, JavaScript, and PHP for email forms.',
        projectUrl: 'https://example.com/hofinda',
        codeUrl: 'https://www.arianegaroi.com',
        buttonTextLive: 'See the Project',
        buttonTextCode: 'See Live Website',
        modalTitle: 'Description',
        modalDescription: 'I developed the frontend of a grid portfolio website for a visual artist using HTML, CSS, and JavaScript,'+
        ' creating a visually appealing and responsive design. Additionally, I implemented PHP to handle email forms, ensuring smooth'+
        ' communication between the artist and potential clients.',
        modalSkills: 'Frontend, UX/UI, Back-End',
        modalSoftware: 'PHP, Wordpress, HMTL, CSS (Bootstrap), Javascript, jQuery',
        modalImages: [vi1, vi2, vi3, vi4, vi5, vi6, vi7, vi8],
        modalImagePath:artistportfolio ,
        modalLink: 'https://www.arianegaroi.com',
        modalButtonText: 'See the Website' // or 'See the website'
      },
      
    
  ];
  


export default projects;
