import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Modal from './Modal';
import ProjectButton from './ProjectButton';
import projects from './ProjectData'; // Import the project data from the separate file
import '@fontsource/inter'; // Defaults to weight 400.
import { gsap } from 'gsap';
import AboutModal from './aboutModal'; // Import the AboutModal component

function App() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [showAboutModal, setShowAboutModal] = useState(false); // State for About Modal
  const buttonsRef = useRef(null); // Reference to the button container
  const gridRef = useRef(null); // Reference to the project grid

  const openModal = (project) => {
    if (!project.url) {
      setSelectedProject(project); // Open modal for project details if no URL is specified
    } else {
      window.open(project.url, '_blank'); // Open external URL in new tab if URL is specified
    }
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  const openAboutModal = () => {
    setShowAboutModal(true);
  };

  const closeAboutModal = () => {
    setShowAboutModal(false);
  };

  useEffect(() => {
    if (buttonsRef.current) {
      gsap.fromTo(
        buttonsRef.current.children,
        { x: -100, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 1,
          ease: 'easeOut',
          stagger: 0.1
        }
      );
    }
  }, []);

  useEffect(() => {
    const cards = document.querySelectorAll('.project-card');
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate');
        }
      });
    }, { threshold: 0.1 });
    
    cards.forEach(card => observer.observe(card));
  }, []);

  const aboutContent = {
    title: "About Me",
    description: `Hi there, this is Ariane :) 🌟

I’m a dedicated Full-Stack Developer and UX/UI Designer with over 5 years of experience in frontend design and 3 years as a full-stack developer. My journey in tech has been driven by a passion for creating intuitive and visually compelling user experiences.

From crafting sleek, user-friendly interfaces to diving into backend development, I love every aspect of the design and development process. Currently, I'm enhancing my skills in backend technologies, particularly Python, to deliver even more robust solutions.

Here's a glimpse of what I do:

- Frontend UX/UI Design: Designing engaging and responsive user interfaces that blend functionality with aesthetic appeal.\n
- Full-Stack Development: Building comprehensive solutions from the ground up, integrating frontend and backend technologies to create seamless experiences.\n
- Python Development: Expanding my expertise to the backend, focusing on writing efficient and scalable code to support dynamic applications.

My approach is all about pushing boundaries and delivering standout solutions that not only meet but exceed expectations. Whether it’s a vibrant brand identity or a complex application, I’m excited to bring innovative ideas to life.

✨ Let's collaborate and turn your vision into reality!`,
    imagePath: "path/to/your/image.jpg"
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="App">
          <header className="App-header">
            <h1 className="hero-title">Full-Stack UX/UI Developer</h1>
            <p className="hero-subtitle">Ariane Souza</p>
          </header>
          <section className="Gallery">
            <h2 className="title_projects">Selected Projects</h2>
            <div className="project-grid" ref={gridRef}>
              {projects.map(project => (
                <div key={project.id} className="project-card" onClick={() => openModal(project)}>
                  <div className="card_container">
                    <span className="container_title"><h1>{project.title}</h1></span>
                    <ProjectButton imagePath={project.imagePath} onClick={() => openModal(project)} />
                    <div className="category-container">
                      {project.category.map((item, index) => (
                        <span key={index} className="category">
                          {item}
                        </span>
                      ))}
                    </div>
                    <span className="description">{project.description}</span>
                    <ul className="ul_buttons">
                      <li>
                        <a 
                          href="#" 
                          className="projetc_button" 
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default anchor behavior
                            openModal(project);
                          }}
                        >
                          <span className="button_text">{project.buttonTextLive || 'See the Project'}</span>
                        </a>
                      </li>
                      <li>
                        <a href={project.codeUrl} className="projetc_button" target="_blank" rel="noopener noreferrer">
                          <span className="button_text">{project.buttonTextCode || 'See the Code'}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
            <div className="spacer"></div>
          </section>
          <div className="spacer"></div> 
          <Modal project={selectedProject} onClose={closeModal} />
          {showAboutModal && (
            <AboutModal
              aboutContent={aboutContent}
              onClose={closeAboutModal}
            />
          )}
        </div>
      </div>
      <footer className="App-footer">
        <button onClick={openAboutModal} className="custom-button">About</button>
        <a href="https://github.com/Arireal" target="_blank" rel="noopener noreferrer">GitHub</a>
        <a href="https://arianesouza-dev.myportfolio.com/" target="_blank" rel="noopener noreferrer">UX/UI Core</a>
        <a href="mailto:your-email@example.com">Contact Me</a>  
      </footer>
    </div>
  );
}

export default App;
