import React, { useState } from 'react';
import './Modal.css'; // Ensure your modal styles are imported

const Modal = ({ project, onClose }) => {
  const [hovered, setHovered] = useState(false);

  if (!project) return null; // If no project is selected, return null to not render anything

  const toggleHover = () => {
    setHovered(!hovered);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        
        <header className="App-header">
        <button
          className={`modal-close ${hovered ? 'active' : ''}`}
          onClick={onClose}
          onMouseEnter={toggleHover}
          onMouseLeave={toggleHover}
        >
          <div className="close-bar"></div>
          <div className="close-bar"></div>
        </button>
          <h1>{project.title}</h1>
        </header>
        <section className="modal-section">
          <div className="modal-left">
            <h2>{project.modalTitle}</h2>
            {project.modalDescription.split('\n\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
          
            <p><strong>Skills:</strong> {project.modalSkills}</p>
            <p><strong>Software and Libraries:</strong> {project.modalSoftware}</p>
            <a
              href={project.modalLink}
              target="_blank"
              rel="noopener noreferrer"
              className={`modal-button ${hovered ? 'hover' : ''}`}
              onMouseEnter={toggleHover}
              onMouseLeave={toggleHover}
            >
              {project.modalButtonText}
            </a>
          </div>
          <div className="modal-right">
            <img src={project.modalImagePath} alt={project.title} />
          </div>
        </section>
        <div className="modal-images">
          {project.modalImages && project.modalImages.map((image, index) => (
            <img key={index} src={image} alt={`Project ${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modal;
