import React, { useState } from 'react';
import './Modal.css'; // Ensure your modal styles are imported

const AboutModal = ({ aboutContent, onClose }) => {
  const [hovered, setHovered] = useState(false);

  if (!aboutContent) return null; // If no content is provided, return null to not render anything

  const toggleHover = () => {
    setHovered(!hovered);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <header className="App-header">
          <button
            className={`modal-close ${hovered ? 'active' : ''}`}
            onClick={onClose}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
          >
            <div className="close-bar"></div>
            <div className="close-bar"></div>
          </button>
          <h1>About Me</h1>
        </header>
        <section className="modal-section">
          <div className="modal-left">
            <h2>{aboutContent.title}</h2>
            {aboutContent.description.split('\n\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
          <div className="modal-right">
            <img src={require(`./images/about-img.png`)} alt="About Me" />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutModal;
