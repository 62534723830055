import React from 'react';

const ProjectButton = ({ imagePath, onClick }) => {
  const buttonStyle = {
    backgroundColor: 'transparent', // Ensure the button background is transparent
    border: 'none', // Remove any default border
    padding: '0', // Remove default padding
    width: '311px', // Ensure the button has the same dimensions as the image
    height: '311px', // Ensure the button has the same dimensions as the image
    borderRadius: '55px', // Apply border radius to the button
    cursor: 'pointer', // Change cursor to pointer
    outline: 'none' // Remove default outline
  };

  const imgStyle = {
    width: '100%', // Make the image take the full width of the button
    height: '100%', // Make the image take the full height of the button
    borderRadius: '55px', // Apply border radius to the image
    objectFit: 'cover' // Ensure the image covers the button area
  };

  return (
    <button style={buttonStyle} onClick={onClick}>
      <img src={imagePath} alt="Project" style={imgStyle} />
    </button>
  );
};

export default ProjectButton;
